.rendered-container {
  position: absolute;
  width: 800px;
  height: 966px;
  font-size: 11px;
  left: -2150px;
  color: black;

  &__content {
    .rendered-filled-measurement-data {
      > hr {
        margin: 8px 0 4px;
      }
      .generated-file-aligned-content {
        .measurement-detergent-name {
          line-height: 30px;
        }
        .formula-version {
          line-height: 30px;
        }
      }
    }

    .rendered-blank-measurement-data {
      > hr {
        margin: 8px 0 4px;
      }
      .generated-file-aligned-content {
        .ant-table {
          line-height: 25px;
          table {
            thead {
              line-height: 24px;
            }
            tbody {
              tr {
                line-height: 25px;
                td {
                  &::first-line {
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
        .measurement-detergent-name {
          line-height: 25px;
        }
        .formula-version {
          line-height: 25px;
        }
      }
    }
  }
}

.generated-file-header-height {
  height: 53px;
}

.production-header-height {
  height: 66px;
}

.production-section {
  padding: 0 40px;
  line-height: 30px;

  h6 {
    line-height: 30px;
    margin-bottom: 0;
  }
}

.generating-file-modal {
  .formula-number-ratio-rawmaterials-producer  {
    thead tr, tbody tr {
      height: 50px;
    }

    tbody tr {
      line-height: 1;
    }

    .ant-table-footer {
      height: 50px;
    }
  }
  
  .formula-project-number {
    tr {
      line-height: 0;
    }
  }

  .measurement-detergent-name {
    line-height: 30px;
    margin-bottom: 0;
  }

  .measurement-page-info {
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.ratios-sum {
  font-family: 'ClanPro-Bold';
  padding: 10px 0 10px 12px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 190px;

  &--wrapper {
    width: 100%;
    padding: 0px 50px 0px 45px;
  }

  &--content {
    max-width: 690px;
    margin: 0 auto;
    background-color: #dbdbdb;
  }

  &--text {
    font-size: 13px;
  }

  &--value {
    font-size: 12px;
    padding-top: 2px;
  }
}

.formula-production-instruction, .formula-detergent-properties {
  margin-top: 25px !important;
  margin-bottom: 0px  !important;
  height: 25px;
  padding: 0 12px;
}

.formula-detergent-properties-text, .formula-production-instruction-text {
  line-height: 25px;
  margin-bottom: 0;
  padding: 0 12px;
}

.formula-title {
  padding: 0 12px;
}

.protocol-signature {
  margin-top: 70px;
}

.generated-file--padding-top {
  padding: 35px 12px 0;
}

.generated-production-instruction {
  padding: 0px 27px;
}
