.detergent {
  .formula {
    float: right;
    margin-right: 20px;
    font-size: $fontSizeOriginal;
    color: $textPlayholder;
  }

  .radio {

    .aSample,
    .abSample,
    .bSample,
    .dSample,
    .formula-sample-radio {
      color: $textColor;
    }
  }

  .ant-col-24 {
    width: 88%;
  }

  &-compare-area {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 25px;
    border: 1px solid #d9d9d9;

    .compare-button {
      font-size: 13px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      border: none;
      color: #646464;
      background-color: #f3f3f3;
      &:hover {
        color: rgba(0, 0, 0, .95);
      }
    }

    .detergent-compare--input {
      background-color: white;
      font-size: 13px;
      padding: 10px 15px;
      min-width: 230px;
      border: none;
      border-right: 1px solid #d9d9d9;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:disabled {
        color: rgba(0,0,0,.65);
      }

      @media screen and (max-width: 1620px) {
        min-width: unset;
        width: 145px;
      }
    }
  }

  &--check-box {
    min-height: 20px;
    visibility: initial;
  }

  #formula-selection-parent-node {
    margin-bottom: 10px;
  }
}

.compare-tooltip {
  padding-top: 0;
  max-width: unset;
  &--item {
    display: flex;
    align-items: center;
    min-width: 300px;
    max-width: 350px;

    &:first-child {
      margin-bottom: 5px;
    }
  }

  &--text {
    margin-left: 10px;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--content {
    min-width: 140px;
  }
}

.blue-text {
  color: $textProgress;
}

.formula-status-approved {
  color: $textGreen;
}

.formula-status-inprogress {
  color: $lightBlueText;
}

.detergent-detail {
  .row-detail {
    display: flex;
    background: $rowBackgroundRawMaterialDetail;
    padding: 10px 20px;
    border-bottom: white solid 1px;
    font-size: 13px;

    .label,
    .columns-label,
    .edit-columns-label {
      width: 25%;
      color: black;
      font-family: $fontClanProBold;
    }

    .value,
    .columns-value,
    .edit-columns-value {
      width: 75%;
      color: #2b2b2b;

      &.status {
        span {
          padding: 8px 16px;
          border-radius: 15px;
          color: white;
          background-color: $backgroundGreen;
        }

        &.Active {
          span {
            background: $textGreen;
          }
        }

        &.Inactive {
          span {
            background: $textGray;
          }
        }

        &.Blocked {
          span {
            background: $textRed;
          }
        }
      }

      .file-info {
        .anticon {
          position: relative;
          top: -2px;
        }
      }
    }

    .columns-label {
      width: 52%;
      &--compare {
        width: 25%;
      }
    }

    .columns-value {
      width: 48%;
      &--compare {
        width: 75%;
      }
    }

    @media screen and (max-width: 1530px) {
      .label--compare,
      .columns-label--compare {
        width: 35%;
      }
      .value--compare,
      .columns-value--compare {
        width: 65%;
      }
    }

    .edit-columns-label,
    .edit-columns-value {
      width: 50%;
    }
  }

  .tabs-detergent-history {
    margin-top: 60px;

    .ant-tabs-bar {
      border-bottom: 5px solid #ffed00;

      .ant-tabs-tab {
        color: black;
        font-weight: bold;
        background: #c2c2c2;
        min-width: 110px;
        text-align: center;
        margin: 0;
        padding: 15px 20px;
        transition: 0.4s;
        margin-left: 1px;
        font-family: $fontClanProBold;
        font-size: 13px;

        &.ant-tabs-tab-active {
          color: black;
          background: #ffed00;
          font-weight: bold;
        }

        &:hover {
          color: black;
          background: #ffed00;
        }
      }

      .ant-tabs-ink-bar {
        background: none;
      }
    }

    .ant-tabs-tabpane {
      // padding-top: 10px;
      // padding-bottom: 20px;
    }

    .raw-material-detail-list-table,
    .precalculation-list-table,
    .measurement-data-table {
      // margin-top: 15px;

      .ant-spin-nested-loading {
        background: #e8e8e8b3;
      }

      .ant-table-content {
        .ant-table-thead>tr:first-child>th:first-child {
          border-radius: 0;
          // border-top-left-radius: 0px !important;
        }

        .ant-table-thead>tr:last-child>th:last-child {
          border-radius: 0;
          // border-top-right-radius: 0px !important;
        }

        .ant-table-tbody {
          tr td:last-child {
            border-radius: 0;
          }

          .ant-table-row {
            .action-col {
              justify-content: start;
              visibility: visible;
            }
          }
        }
      }

      .ant-spin-dot {
        font-size: 30px;
      }

      .ant-table-row-cell-break-word {
        border-bottom: 2px solid $backgroundWhite;
      }

      .ant-table-bordered .ant-table-body>table {
        border: transparent;
      }

      .ant-select-dropdown-placement-bottomLeft,
      .ant-select-dropdown--single {
        width: 7%;
      }
    }

    .precalculation-list-table {
      .ant-table-content {
        .ant-table-thead {
          tr>th:not(:first-child) {
            .ant-table-header-column {
              float: right;
            }
          }

          tr>td:first-child {
            padding: 10px 16px;
          }
        }

        .ant-table-footer {
          padding: 0;
          font-family: $fontClanProBold;

          td {
            padding: 4px 16px;
          }

          .sum-prices-title {
            color: $textPlayholder;
          }

          .sum-prices {
            float: right;
            text-align: right;
            color: $textGreen;
          }
        }
      }
    }

    .table__back-to-top {
      visibility: hidden;
    }

    .ant-table-row-cell-ellipsis {
      overflow-x: auto;

      .div-changes {
        overflow-x: auto;
        scrollbar-color: rgb(223, 221, 221) #f1f1f1;
        scrollbar-width: thin;
      }
    }

    .history-details {
      .title {
        font-size: 13px;
      }

      .history-list-detail {
        border: 1px solid $borderGrey;
        margin-top: 10px;

        .table__pagination-wrapper {
          .table__page-size-option {
            padding-left: 16px;
          }
        }
      }
    }

    .rawMaterial-detail {
      border: 1px solid $borderGrey;
    }
  }

  .form-btn-actions {
    padding-right: 0;
  }

  .precalculation-details {
    .precalculation-container-size {
      height: 35px;
      margin-bottom: 10px;

      .container-size-wrapper {
        float: right;
        margin-bottom: 20px;
        display: inline-flex;

        .container-size-title {
          font-family: $fontClanProBold;
          color: $textBlack;
        }

        .container-size-input {
          width: 50%;
        }

        span:nth-child(2n + 1) {
          padding: 5px;
        }
      }
    }

    .precalculation-list-table {
      input {
        text-align: right;
      }

      .raw-material-column-title {
        text-transform: uppercase;
      }

      .ant-table-row {
        .ant-table-row-cell-break-word {
          padding: 4px;
          padding-left: 16px;

          &:first-child {
            padding-top: 12px;
          }
        }

        .precalculation-ratio {
          padding: 4px;
        }
      }
    }

    .ant-spin-nested-loading .ant-spin-container {
      background-color: $backgroundWhite;
    }
  }

  .ant-table-row-cell-break-word {
    font-family: $fontClanProBold;
  }

  .select-document-production-cycles {
    flex: 1;
    .ant-select .ant-select-selection {
      border-color: $borderbtn;
    }
    .ant-select-arrow {
      color: $borderbtn;
    }
  }
}

.hide-action-btn {
  visibility: hidden;
}

.sample-cell {
  &:hover {
    .formula-edit-icon {
      visibility: visible;
    }
  }
}

.sample-wrapper {

  // position: absolute;
  .select-margin-top {
    margin-top: -10px;
  }

  .formula-actions-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backgroundWhite;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 100px;
    z-index: 10;
    left: 50px;
    top: 10px;

    .edit-formula-action-btn {
      width: 30px;
      height: 30px;
      margin: 4px;
      padding: 4px;
    }
  }

  .detergent-sample,
  .formula-edit-icon {
    float: right;
    margin-right: 64px;
    visibility: hidden;
    position: relative;
  }

  .formula-edit-icon {
    @media screen and (max-width: $mediumDesktop) {
      margin-right: 0px;
    }
  }

  &:hover {

    .detergent-sample,
    .formula-edit-icon {
      visibility: visible;
    }
  }

  .ant-form {
    min-width: 160px;
    max-height: 0;
  }

  .ant-select {
    min-width: 160px;
    margin: -10px;
  }
}

.hide-fields,
.hide-container-size-field {
  border: none;
  color: $fontColorTable !important;

  :hover {
    border: none;
    color: $fontColorTable !important;
  }
}

.hide-fields {
  background: $rowBackgroundRawMaterialDetail;
}

.hide-container-size-field {
  background: $backgroundWhite !important;
  text-align: right;
}

.hide-select-fields {
  .ant-select-selection--single {
    border: none;
    color: $fontColorTable;

    .ant-select-arrow {
      display: none;
    }
  }
}

.formula-select-fields {
  max-width: 120px;

  .ant-select-selection {
    background-color: transparent;
  }
}

.update-formula-form {
  border: 1px solid #d9d9d9;
  padding: 11px 20px;
  width: 300px;
  background-color: #ffffff;
  border-bottom: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1060;
}

.precalculation-list-table-wrapper,
.measurement-data-table {
  .ant-table-tbody {
    .ant-table-row {
      margin-bottom: 2px;

      td {
        background-color: $rowBackgroundRawMaterialDetail;
      }
    }
    tr {
      td {
        padding: 2px;
        padding-left: 16px;
      }
    }
    :hover{
      .measure-display{
        background-color: $hoverInput;
      }
    }
  }
  .ant-table-thead{
    tr{
      th{
        padding: 9px;
        padding-left: 16px;
        padding-top: 25px !important;
      }
    }
  }
  .tolerance-value {
    text-align: end;
    width: 65%;
    font-size: 13px;
  }
}

.measure-display {
  border: none;
  background: $rowBackgroundRawMaterialDetail;
  color: $fontColorTable !important;
  font-family: $fontClanProNews;

  :hover {
    border: none;
    color: $fontColorTable !important;
  }
}
.measure-edit{
  width: 70%;
}

.measure-edit-comment{
  width: 90%;
}

.measure-storage {
  .ant-select-selection--single {
    border: none;
    color: $fontColorTable;
    font-family: $fontClanProNews;

    .ant-select-arrow {
      display: none;
    }
  }
}

.phvalue-select {
  .ant-select-selection--single {
    color: $fontColorTable;
    // .ant-select-arrow{
    //   display: none;
    // }
  }
}

.measure-lab-info {
  background: white !important;
  border: none;
  color: $fontColorTable !important;
}

.select-passfailAll-edit {
  // width: 70% !important;
  .ant-select-selection--single {
    min-width: 122px;
  }

  .ant-select-dropdown-placement-bottomLeft {
    width: 106px !important;
  }
}

.select-yesno-edit {
  width: 70% !important;
}

.select-passfail-read {
  .ant-select-selection--single {
    border: none;
    color: $fontColorTable;
    background: white;

    .ant-select-arrow {
      display: none;
    }
  }
}

.select-passfailAll-read {
  .ant-select-selection--single {
    border: none;
    color: $fontColorTable;
    background: white;

    .ant-select-arrow {
      display: none;
    }
  }
}

.select-yesno-read {
  .ant-select-selection--single {
    border: none;
    color: $fontColorTable;
    background: transparent;

    .ant-select-selection__rendered {
      width: fit-content;
    }

    .ant-select-arrow {
      display: none;
    }
  }
}
.measurement-temperature-input-read {
  color: $fontColorTable !important;
  border: none;
}
.checkbox-read {
  color: white;

  .ant-checkbox-inner {
    border: transparent;
  }
}

.checkbox-edit {
  margin-left: 100px;
}

.measurement-title {
  padding-left: 11px;
  font-size: $fontSizeSmall;
}

.ant-select-dropdown-menu-item {
  span {
    color: $fontColorTable !important;
  }
}
.measure-storage-style{
  .ant-table-thead{
    .ant-table-row-cell-break-word:nth-child(2){
      padding-left: 16px;
    }
  }
}

.ant-tooltip-inner {
  font-family: $fontClanProMedium;
  font-size: $fontSizeSmall;
  padding: 5px 10px;
  min-height: 25px;
}

.ant-tooltip-arrow,
.hide-edit-button {
  visibility: hidden;
}

.generating-file-modal {
  .ant-modal-content .ant-modal-body {
    max-height: 60vh !important;
    overflow-y: hidden !important;
    padding-left: 150px;
  }
}

.generating-file-save-button {
  background: #ffed00;
}

.production-cycle-buttons {
  margin-bottom: 10px;
}

.production-cycle--edit-table {
  @media screen and (max-width: 1440px) {
    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  @media screen and (max-width: 1200px) {
    .ant-table-tbody .ant-table-row td {
      font-size: 10px;
    }

    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th {
      padding-right: 0;
    }

    .comment-row {
      .ant-form-item-label {
        text-align: left;
      }
    }

    .ant-select {
      font-size: 11px;
    }
    
    .production-cycle-list .ant-calendar-picker {
      width: 110px;
    }
  }
}

.add-production-cycle {
  .formItem {
    display: flex;
    padding: 0 20px;

    .label-col {
      width: 25%;
    }

    .value-col {
      width: 75%;
    }

    .delete-item {
      display: flex;
      color: $colorDelete;
      font-size: 13px;
      margin-top: 15px;
      cursor: pointer;

      .delete-icon {
        margin-right: 5px;
      }
    }
  }
}

.upload-detergent {
  .ant-form-explain {
    background: $rowBackgroundRawMaterialDetail;
    display: flex;
    justify-content: flex-end;
    padding-right: 18%;
  }
}

.precalculation-list-table,
.detergent-production-cycle-table,
.detergent-partnumber-table,
.detergent-detail .detergent-history-table,
.detergent-production-cycle-table {
  .ant-table-row-cell-break-word {
    font-family: $fontClanProNews;
  }
}

.detergent-production-cycle-table {
  .DELETE_PRODUCTION_CYCLE {
    margin-left: 10px;
    padding-bottom: 2px;
  }
}

.detergent-precalculation-footer {
  .ant-table-row-cell-break-word {
    font-family: $fontClanProBold;
  }
}

.upload-detergent {
  .ant-form-explain {
    background: $rowBackgroundRawMaterialDetail;
    display: flex;
    justify-content: flex-end;
    padding-right: 18%;
  }
}

.production-cycle-list {
  .ant-calendar-picker {
    width: 130px;
  }

  .ant-table-row-cell-break-word {
    font-family: $fontClanProNews;
  }

  .title {
    font-family: "ClanPro-Bold";
  }

  .ant-table-tbody {
    >tr:not(.ant-table-expanded-row)>td {
      border-bottom-style: dashed;
    }

    >tr.ant-table-expanded-row>td {
      padding: 0;
    }
  }

  .ant-table table {
    border: 1px solid $borderGrey;
  }

  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row:hover {
    background: $backgroundWhite;
  }

  .expanded-production-cycle-row {
    .col-left {
      padding-right: 10px;
    }

    .col-right {
      padding-left: 10px;
    }

    @media screen and (max-width: 1199px) {
      .col-left {
        padding-right: 0;
      }
  
      .col-right {
        padding-left: 0;
      }
    }

    .row-item {
      background-color: $rowBackgroundRawMaterialDetail;
      min-height: 38px;
      border-bottom: 1px solid $backgroundWhite;
      align-items: center;
      display: flex;
      padding-left: 20px;

      &.radio-group-vertical {
        border: none;
        padding-left: 0;

        .radio-group {
          width: 100%;

          .radio-item {
            width: 100%;
            height: 38px;
            line-height: 38px;
            padding-left: 80px;
            border-bottom: 1px solid $backgroundWhite;
          }
        }
      }

      >button {
        height: 28px;
        line-height: 28px;
      }

      &.level-2 {
        padding-left: 62px;
      }

      &.level-3 {
        padding-left: 79px;
      }
    }

    .comment-row {
      margin-bottom: 0px;
      padding: 10px 20px 0;

      label {
        font-family: "ClanPro-Bold";
      }
    }
  }
}

.checkbox-square {
  .ant-checkbox-inner {
    border: 1px solid $boderCheckbox;
    border-radius: 0;
    width: 20px;
    height: 20px;

    &:after {
      top: 42% !important;
      left: 15% !important;
    }
  }

  >span+span {
    padding-left: 22px;
    color: $textColor;
  }
}

.editable-raw-material-tab {
  .ant-select-dropdown-menu {
    max-height: 100px;
  }
}

.editable-raw-material-tab {
  .formular-selection {
    min-height: 110px;
    background: $rowBackgroundRawMaterialDetail;
  }
}

.partNumberSelection {
  .ant-form-explain {
    margin-top: 2px;
  }
}

.text-reviewed-note {
  width: fit-content;
  margin-left: 10px;
}

.table-detergent {
  .ant-table-expanded-row td[colspan="6"]:empty { 
    display: none;
  }

  @media screen and (max-width: 1580px) {
    .action-col {
      .ADD_NEW_FOMULAR,
      .DUPLICATE_DETERGENT,
      .VIEW_DETERGENT_DETAIL,
      .EDIT_DETERGENTS {
        margin-right: 15px !important;
      }
      .margin-left {
        margin-left: 10px;
      }
      .action-margin-left {
        margin-left: 34px;
      }
    }
  }

  @media screen and (max-width: $mediumDesktop) {
    .action-col {
      .ADD_NEW_FOMULAR,
      .DUPLICATE_DETERGENT,
      .VIEW_DETERGENT_DETAIL,
      .EDIT_DETERGENTS {
        margin-right: 12px !important;
      }
      .margin-left {
        margin-left: 15px;
      }
      .action-margin-left {
        margin-left: 39px;
      }
    }
  }

  @media screen and (max-width: $smallDesktop) {
    .action-col {
      .ADD_NEW_FOMULAR,
      .DUPLICATE_DETERGENT,
      .VIEW_DETERGENT_DETAIL,
      .EDIT_DETERGENTS {
        margin-right: 2px !important;
      }
      .CHECK_BOX_DETERGENTS {
        margin-right: 5px !important;
      }
      .margin-left {
        margin-left: 5px;
      }
      .action-margin-left {
        margin-left: 29px;
      }
      .action-btn-margin-left {
        margin-left: 18px;
      }
      .action-item-visible {
        margin-right: 2px !important;
      }
      .action-item-visible-bold:last-child {
        margin-right: 2px !important;
      }
    }

    .PREVENT_ON_ROW_TRIGGER {
      font-size: $fontSizeSmall;
      .sample-wrapper {
        .ant-form {
          font-size: $fontSizeSmall;
        }
      }
    }
  }
}
.raw-material-detail-list-table{
  .ant-table-body{
    tr{
      td{
        padding: 9px;
        padding-left: 16px;
      }
    }
  }
  .ant-table-thead{
    tr{
      th{
        padding: 9px;
        padding-left: 16px;
      }
    }
  }
}

.detergent-common-modal{
  .ant-modal-body {
    max-height: 88vh !important;
  }
}

.add-production-cycle-modal {
  .ant-modal-body {
    overflow: unset !important;
    ._loading_overlay_wrapper {
      overflow: unset;
    }
  }
}

.compare-detergents {
  &-modal {
    display: flex;
    justify-content: space-between;
  }
  &--detail {
    flex: 1;
    &:first-child {
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 5px;
    }

    @media screen and (max-width: 1200px) {
      .row-detail {
        padding: 10px 8px;
      }
    }
  }

  &--raw-material {
    display: inline-block;
    width: 100%;
    margin-top: 25px;
  }

  &--tab {
    border-bottom: 5px solid #ffed00;
    padding: 15px 0 10px;
    margin-bottom: 15px;
    &-title{
      color: black;
      background: #ffed00;
      font-weight: bold;
      padding: 15px 20px;
      text-align: center;
      max-width: 170px;
    }
  }

  &--table {
    border: 1px solid #e8e8e8;
    .ant-spin-nested-loading {
      background: #e8e8e8b3;
    }

    .ant-spin-dot {
      font-size: 30px;
    }

    .ant-table-row-cell-break-word {
      border-bottom: 2px solid $backgroundWhite;
      p {
        margin-bottom: 0;
      }
    }

    .ant-table-thead {
      .ant-table-row-cell-break-word {
        &:first-child {
          padding-right: 10px;
        }
        &:nth-child(6) {
          padding-left: 0;
          padding-right: 8px;
        }

        @media screen and (max-width: 1530px) {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            padding-left: 10px;
            padding-right: 5px;
          }
        }

        @media screen and (max-width: 1200px) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row-cell-break-word {
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
}

.detergent-compare--tooltip {
  padding-top: 5px;
}

.formulas-tab {
  .filter-area {
    .content-filter-form {
      min-width: 450px;
    }
  }
  @media screen and (max-width: 1530px) {
    .filter-area {
      .search-place {
        margin-left: 5px;
      }
  
      #dropdown-parent-node {
        margin-right: 10px !important;
      }
  
      .detergent-compare-area {
        margin-right: 13px;
      }
  
      .add-button {
        margin-left: 15px;
      }
    }
  }

  @media screen and (max-width: $mediumDesktop) {
    .filter-area {
      .search-place {
       margin-left: 0;
      }
  
      #dropdown-parent-node,
      .detergent-compare-area {
        margin-right: 7px !important;
      }
      
      .add-button {
        margin-left: 7px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .filter-area {
      .detergent-compare--input,
      .compare-button {
        font-size: $fontSizeSmall;
        button:disabled {
          padding: 0 8px;
          margin-right: -8px;
          .text {
            font-size: $fontSizeSmall;
          }
        }
      }

      .search-place {
        margin-left: 7px;
      }

      .add-button {
        padding-left: 10px;
        padding-right: 10px;
      }
      
      .content-filter-form {
        top: 62px !important;
      }
      .compare-button {
        margin-right: 2px;
        padding: 0 8px;
      }
    }
  }

  @media screen and (max-width: $smallDesktop) {
    .filter-area {
      .detergent-compare-area {
        margin-right: 5px;
      }
  
      .search-place .ant-input {
        width: 140px;
        padding-left: 8px;
        padding-right: 8px;
      }

      #dropdown-parent-node {
        margin-right: 0 !important;
      }
    }
  }
}
