.contact-producer {
  .info-producer {
    margin-bottom: 0px;

    .title-contact-person {
      font-size: $fontSizeOriginal;
      color: $textPlayholder;
      margin-top: 20px;
      text-align: right;
      width: 15%;
    }

    .add-more {
      display: flex;
      color: $textGreen;
      font-size: $fontSizeOriginal;
      margin-top: 15px;
      margin-bottom: 40px;
      cursor: pointer;
    }

    .comment {
      label {
        font-size: $fontSizeOriginal;
        color: $textPlayholder;
        opacity: $opacityPlayholder;
      }
    }

    .remove {
      display: flex;
      color: $colorDelete;
      font-family: $fontClanProNews;
      font-size: $fontSizeOriginal;
      opacity: $opacityPlayholder;
      float: right;
      cursor: pointer;
    }
  }
}