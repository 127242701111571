.preview-raw-material-files {
  margin-top: 1rem;

  .file-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0.5rem;

    .file-info {
      display: flex;
      align-items: center;
      flex: 1;

      > i {
        margin-right: 5px;
      }

      .fileName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 70%;
        text-transform: lowercase;
        text-decoration: underline;
        font-size: 12px;
        line-height: normal;
      }

      > button {
        color: $sideBarBackground;
        height: auto;
        line-height: 0.8;
        margin-top: -2px;
      }
    }

    .select-document-category {
      flex: 1;
      .has-non-error-document {
        .has-error,
        .ant-select-selection {
          border-color: $borderbtn;
          .has-error,
          .ant-select-arrow {
            color: $borderbtn;
          }
        }
      }
    }
  }
}

.file-category-preview {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  &-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
  }

  &:last-child {
    border-bottom: none;
  }

  .preview-files-info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &--item {
      display: flex;
      align-items: center;
    }
    &--cycle-ids {
      font-size: 14px;
      font-weight: 900;
      color: $textBlack;
      margin-left: 5px;
    }
  }
} 
