.generated-file-aligned-content {
  padding: 5px 40px 0px;
}

.generated-file-header {
  display: flex;
  justify-content: space-between;
  .formula-logo {
    height: 40px;
  }

  .formula-meta-data {
    .production-instructions {
      text-align: right;
    }
    h4 {
      text-align: right;
    }
  }
}

.generated-file-detergent-name {
  font-size: large;
  font-weight: bold;
  width: 237px;
}

.generated-file-bordered-table {
  width: 46%;

  tr {
    &:first-child {
      th {
        text-align: center;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      td {
        padding-left: 10px;
      }
    }
  }
}

.generated-file-bordered-table td,
.generated-file-bordered-table th {
  border: 2px solid black;
  padding-top: 0px;
  padding-bottom: 0px;
}

.generated-file-detergent-name-container {
  display: flex;
  justify-content: space-between;
}

.generated-file-detergent-name-label {
  margin-right: 15px;
  text-align: right;
}

.generated-file-aligned-content {
  padding: 0px 50px 0px 40px;
  .formula-version {
    margin-left: 24%;
  }
  .formula-title,
  .formula-production-instruction,
  .formula-detergent-properties {
    margin-top: 30px;
  }

  table, tr, td, th, tbody, thead {
    page-break-inside: avoid !important;
  }

  .ant-table-wrapper {
    .ant-table-tbody {
      > tr {
        page-break-inside: avoid;
        > td {
          border-bottom: 1px solid #000000;
        }
      }
    }
    .ant-table-thead {
      display: table-header-group;
      > tr {
        page-break-inside: avoid;
        > th {
          border-bottom: 1px solid #000000;
        }
      }
    }
  }
  strong {
    font-family: "ClanPro-Bold";
  }
  .detergent-name-label-pdf {
    padding-left: 12px;
  }
  .formula-project-number {
    .ant-table-tbody {
      .ant-table-row {
        font-family: "ClanPro-Bold";
      }
    }

    .ant-table-tbody {
      > tr {
        > td {
          border-bottom: none !important;
        }
      }
    }
  }
  .formula-number-ratio-rawmaterials-producer {
    .ant-table-thead {
      .ant-table-header-column {
        font-family: "ClanPro-Bold";
        color: #000000;
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        font-family: "ClanPro-New";
      }
    }
    .ant-table-footer {
      strong {
        display: flex;
        float: right;
        padding-right: 472px;
      }
    }
    .column-money {
      text-align: center;
      padding-right: 20px;
    }
  }
}

.generated-file-measurement-table {
  .ant-table-tbody > tr {
    vertical-align: top;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 0 2px;
    border: 1px solid #00000081;
    border-bottom: none;
    border-right: none;
  }

  .ant-table-thead > tr {
    .none-border-left {
      border-left: none;
    }
    .none-border-right {
      border-right: none;
    }
  }
  
  .ant-table {
    line-height: 30px;
    table {
      border-collapse: collapse;
      thead {
        line-height: 29px;
      }
      tbody {
        tr {
          line-height: 30px;
          td {
            label {
              line-height: 29px;
            }
            &::first-line {
              line-height: 29px;
            }
          }
        }
      }
    }
  }
  &.measurement-test-table {
    .ant-spin-nested-loading
      .ant-spin-container
      .ant-table-bordered
      .ant-table-content
      .ant-table-body
      table
      thead
      tr:first-child
      th:last-child {
      width: 200px;
    }

    .ant-spin-nested-loading
      .ant-spin-container
      .ant-table-bordered
      .ant-table-content
      .ant-table-body
      table
      thead {
      tr:nth-child(4) th:last-child {
        width: 92px;
      }

      tr:nth-child(4) th:nth-child(3) {
        width: 92px;
      }
    }
    .ant-table-row-cell-last {
      border-right: 1px solid !important;
    }
    .ant-table-tbody {
      > tr {
        > td {
          border: 1px solid #00000081;
        }
      }
    }
    .ant-table-body {
      .ant-table-thead {
        .ant-table-header-column {
          font-family: "ClanPro-Bold";
          color: #000000;
        }
      }
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 11px !important;
  }

  &.measurement-other-table,
  &.measurement-safety-table,
  &.measurement-ph-value-table,
  &.measurement-storage-test-table,
  &.measurement-other-table-yes-no {
    .ant-table-row-cell-last {
      border-right: 1px solid !important;
    }
    .ant-table-tbody {
      > tr {
        > td {
          border: 1px solid #00000081;
        }
      }
    }
    .ant-table-body {
      .ant-table-thead {
        .ant-table-header-column {
          font-family: "ClanPro-Bold";
          color: #000000;
        }
      }
    }
  }
  &.measurement-ph-value-table,
  &.measurement-safety-table,
  &.measurement-storage-test-table {
    .ant-input {
      height: unset;
    }
  }
}

.technical-quality-control-part-number {
  width: 54%;
}

.container-label-right section span:first-child {
  text-align: right;
  margin-right: 5px;
}

.technical-quality-control-footer-label {
  width: 57% !important;
}

.measurement-sheet-two-change-note,
.measurement-sheet-two-formula-meta {
  border: 1px black;
  border-style: none solid solid solid;
}

.measurement-sheet-two-change-note {
  padding: 0 8px;
  p {
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.measurement-sheet-two-formula-meta {
  display: flex;
  line-height: 30px;
  section {
    width: 360px;
    padding: 0 8px;
    p {
      margin-bottom: 0;
    }
  }

  section:first-child {
    border-right: 1px solid #00000081;
  }
}

.production-protocal-footer-line {
  margin-top: 60px;
  display: flex;
  justify-content: space-between
}

.production-test-protocol-label {
  text-align: right;
  margin-right: 15px;
}

.production-test-protocol-batch-label {
  text-align: right;
  margin-right: 12px;
  padding-right: 8px;
}

.test-resulted,
.note-changes {
  font-size: 16px;
  font-family: "ClanPro-Bold";
  color: #000000;
  line-height: 30px;
  margin-bottom: 0;
}

.test-resulted-note {
  font-size: 12px;
  font-family: "ClanPro-Bold";
  color: #000000;
  line-height: 30px;
  margin-top: -40px;
  padding-top: -40px;
  padding-bottom: 10px;
}

.test-resulted {
  &--wrapper {
    width: 35%;
  }

  &--checkbox {
    width: 65%;
  }
  
  &--note {
    width: 100%;
  }
}

.tab-water-generate {
  border: none;
  background: #ffffff !important;
  color: #000000 !important;
  padding: 0px 0px;
  width: 26px;
  font-size: 11px;
}
