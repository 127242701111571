.production-cycles {
  &--tab {
    .filter-area {
      .content-filter-form {
        min-width: 420px;
      }

      .sort-dropdown.ant-dropdown-open {
        padding: 8px 20px 10px;

        .edit_caret {
          margin-top: -1px;
        }
      }

      .content-sort-form {
        top: 39px !important;

        .menu-sort {
          .sort-item {
            &:hover {
              background-color: white;
            }
          }
        }
      }

      @media screen and (max-width: $mediumDesktop) {
        .content-filter-form {
          top: 62px !important;
        }
      }

      @media screen and (max-width: $smallDesktop) {
        .content-filter-form {
          top: 61px !important;
        }
      }
    }

    .ant-dropdown-open {
      border: 1px solid #d9d9d9;
      padding: 10.5px 20px 11px;
      background-color: #ffffff;
      border-bottom: none;
      position: relative;
      z-index: 1060;

      .edit_filter {
        margin-top: -4.5px;
      }
    }
  }

  &--filter {
    display: flex;
    cursor: pointer;
    padding: 11px 21px;

    .sort {
      font-size: 13px;
    }
    
    .edit_filter {
      margin-right: 5px;
      margin-top: -4px;
    }
  }

  &--table {
    .ant-table-row {
      &:hover {
        .production-cycles--detergent-column {
          .action-item {
            opacity: 1;
          }
        }
      }
    }
  }

  &--detergent-column {
    display: flex;
    justify-content: space-between;

    .action-item {
      opacity: 0;
    }

    &:hover {
      .action-item {
        opacity: 1;
      }
    }
  }

  &--checkbox {
    .ant-checkbox {
      .ant-checkbox-inner:after {
        width: 5.5px !important;
        height: 11px !important;
      }
    }
  }
}

.production-cycles--filter-content {
  .filter-production-cycle-item {
    display: flex;
    align-items: center;
  }
}
