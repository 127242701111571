// .annual-tonnage {
//   display: inline-flex;
//   position: relative;
//   .action-col {
//     // justify-content: start !important;
//     position: absolute;
//   }
//   .annual-tonnage-item {
//   }
// }

.add-more {
  display: flex;
  color: $textGreen;
  font-size: $fontSizeOriginal;
  margin-top: 15px;
  cursor: pointer;

  .add-more-plus {
    margin-right: 5px;
  }
}

.remove {
  display: flex;
  color: $colorDelete;
  font-family: $fontClanProNews;
  font-size: $fontSizeOriginal;
  opacity: $opacityPlayholder;
  float: right;
  cursor: pointer;
}

.annual-demand-label {
  display: grid;

  span {
    height: 20px;
    position: relative;
    top: -12px;
  }

  .required-mark {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    top: 0;
  }
}

.filter-partNumber {
  display: flex;
  cursor: pointer;
  padding: 11px 21px;
  align-items: center;

  &.ant-dropdown-open {
    border: 1px solid #d9d9d9;
    padding: 11px 20px;
    background-color: #ffffff;
    border-bottom: none;
    // box-shadow: 0 2px 8px rgba(0,0,0,.15);
    position: relative;
    z-index: 1060;
  }

  .edit_filter {
    margin-right: 5px;
    margin-top: -6px;

    .anticon-filter {
      color: $mainTitle;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .edit_caret {
    margin-right: 5px;
    display: grid;
    margin-top: -2px;

    .anticon {
      color: $sideBarBackground;

      &.anticon-caret-up {
        margin-bottom: -2.5px;
      }

      &.anticon-caret-down {
        margin-top: -2.5px;
      }
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .sort {
    font-size: $fontSizeOriginal;
    color: $mainTitle;
  }
}

.partnumber-filter-area {
  .content-filter-form {
    min-width: 400px;

    .filter-detergent-form_sample,
    .filter-select {
      margin-top: 8px;
    }
  }

  .filter-partNumber {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: $smallDesktop) {
    .partnumber--migrate-button {
      font-size: $fontSizeSmall;
      padding: 10px;
    }

    .filter-partNumber {
      margin-left: 2px;
      margin-right: 2px;
    }

    .ant-dropdown-open {
      padding-bottom: 10px !important;
    }

    .search-place {
      margin-left: 0;
      .ant-input {
        width: 155px;
      }
    }
    .add-button {
      margin-left: 5px;
    }
  }
}

.filter-content {
  width: 400px;
}

.tabs-partNumber-history {
  margin-top: 40px;

  .ant-tabs-bar {
    border-bottom: 5px solid #ffed00;

    .ant-tabs-tab {
      color: black;
      font-weight: bold;
      background: #c2c2c2;
      min-width: 110px;
      text-align: center;
      margin: 0;
      padding: 15px 20px;
      transition: 0.4s;
      margin-left: 1px;
      font-family: $fontClanProBold;
      font-size: 13px;

      &.ant-tabs-tab-active {
        color: black;
        background: #ffed00;
        font-weight: bold;
      }

      &:hover {
        color: black;
        background: #ffed00;
      }
    }

    .ant-tabs-ink-bar {
      background: none;
    }
  }

  .ant-tabs-tabpane {
    // padding-top: 10px;
    // padding-bottom: 20px;
  }

  .table__back-to-top {
    visibility: hidden;
  }

  .history-details {
    .title {
      font-size: 13px;
    }

    .history-list-detail {
      border: 1px solid $borderGrey;
      margin-top: 10px;

      .table__pagination-wrapper {
        .table__page-size-option {
          padding-left: 16px;
        }
      }
    }
  }

  .annual-demand-detail {
    .annual-demand {
      margin-top: 15px;

      .ant-spin-nested-loading {
        background: #e8e8e8b3;
      }

      .ant-table-content {
        .ant-table-thead > tr:first-child > th:first-child {
          border-radius: 0;
          // border-top-left-radius: 0px !important;
        }

        .ant-table-thead > tr:last-child > th:last-child {
          border-radius: 0;
          // border-top-right-radius: 0px !important;
        }

        .ant-table-tbody {
          tr td:last-child {
            border-radius: 0;
          }

          .ant-table-row {
            .ant-table-row-cell-break-word {
              background: $rowBackgroundRawMaterialDetail;
            }

            .action-col {
              justify-content: start;
              visibility: visible;
            }
          }
        }
      }

      .ant-spin-dot {
        font-size: 30px;
      }

      .ant-table-row-cell-break-word {
        border-bottom: 2px solid $backgroundWhite;
      }

      .ant-table-bordered .ant-table-body > table {
        border: transparent;
      }
    }
  }
}

.normal-text,
.annual-demand .ant-table-row-cell-break-word input,
.partnumber-history-table .ant-table-row-cell-break-word {
  font-family: $fontClanProNews;
}
.annual-tonnage {
  display: inline-flex;
  position: relative;
  .annual-tonnage-item {
    position: absolute;
    right: 0;
    top: 10%;
  }
}

.partnumber--migrate-button {
  display: flex;
  align-items: center;
  height: auto;
  padding: 10px 20px;
  border: 1px solid #d9d9d9;

  font-size: 13px;
  color: #000;
  background-color: #fff;

  &:hover, &:active, &:focus {
    color: #000;
    border: 1px solid #d9d9d9;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  .text {
    padding-top: 2px;
  }
}

.migrate-annual-demand {
  &--upload {
    display: flex;
    align-items: center;

    color: #2b2b2b;
    font-size: 13px;

    &:hover, &:active, &:focus {
      color: #2b2b2b;
      border: 1px solid #d9d9d9;
    }

    &:disabled {
      .upload-icon {
        color: rgba(0,0,0,.25);
      }
    }
  }

  .upload-icon {
    margin-right: 5px;
    padding-bottom: 1px;
    color: black;
    vertical-align: 1px;
    font-size: 15px;
  }
}

.part-number-status-wrapper {
  .select-margin-top {
    margin-top: -10px;
  }

  .formula-actions-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backgroundWhite;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 100px;
    z-index: 10;
    left: 50px;
    top: 10px;

    .edit-formula-action-btn {
      width: 30px;
      height: 30px;
      margin: 4px;
      padding: 4px;
    }
  }

  .part-number-edit-icon {
    float: right;
    margin-right: 64px;
    visibility: hidden;
    position: relative;
  }

  .part-number-edit-icon {
    @media screen and (max-width: $mediumDesktop) {
      margin-right: 0px;
    }
  }

  &:hover {
    .part-number-edit-icon {
      visibility: visible;
    }
  }

  .ant-form {
    min-width: 160px;
    max-height: 0;
  }

  .ant-select {
    min-width: 160px;
    margin: -10px;
  }

  .hide-action-btn {
    visibility: hidden;
  }
}

.disabled-select-part-number {
  font-weight: 600;
  color: rgba(0,0,0,.25);
  background-color: #fafafa;
  pointer-events: none;
  i {
    display: none !important;
  }
}