.material-group {
  label {
    font-size: $fontSizeOriginal;
    font-family: $fontClanProNews;
    color: $textColor;
  }
}
.DELETE_RAW_MATERIAL_GROUP, .edit-rawMaterialGroup{
  color: $sideBarBackground;
}

.name{
  .ant-select-dropdown{
    top: 50% !important;
    margin-top: 35px;
  }
}

.rmg-filter-area {
  .filter-rmg {
    margin-right: 20px;
  }
  .content-filter-form {
    min-width: 400px;
    .filter-select {
      margin-top: 8px;
    }
  }
  .filter-content {
    width: 380px;
  }

  @media screen and (max-width: $smallDesktop) {
    .filter-rmg {
      margin-right: 0;
    }
    .content-filter-form {
      top: 61px !important;
    }
    .ant-dropdown-open {
      padding-bottom: 10px !important;
    }
    .search-place {
      margin-left: 5px;
      .ant-input {
        width: 170px;
      }
    }
    .add-button {
      margin-left: 10px;
      padding: 10px 10px;
    }
  }

}

.filter-rmg {
  display: flex;
  cursor: pointer;
  padding: 11px 21px;
  align-items: center;

  &.ant-dropdown-open {
    border: 1px solid #d9d9d9;
    padding: 11px 20px;
    background-color: #ffffff;
    border-bottom: none;
    position: relative;
    z-index: 1060;
  }

  .edit_filter {
    margin-right: 5px;
    margin-top: -6px;

    .anticon-filter {
      color: $mainTitle;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .edit_caret {
    margin-right: 5px;
    display: grid;
    margin-top: -2px;

    .anticon {
      color: $sideBarBackground;

      &.anticon-caret-up {
        margin-bottom: -2.5px;
      }

      &.anticon-caret-down {
        margin-top: -2.5px;
      }
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .sort {
    font-size: $fontSizeOriginal;
    color: $mainTitle;
  }
}
