.db-header {
  height: $headerHeight;
  padding: 15px;
  padding-left: 0px;
  background: white;
  border: 2px solid #e8e8e8;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 3px 3px #e8e8e8;
  .title {
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px;
    color: $fontColorTable;
    font-family: $fontClanProMedium;

    @media screen and (max-width: $smallDesktop) {
      font-size: 20px;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    .language,
    .ant-select-open,
    .ant-select-focused {
      margin-right: 20px;
      color: $fontColorTable;
      .ant-select-selection {
        display: flex;
        align-items: center;
        border: 0px;

        &:hover {
          border: 0px;
        }
        &:focus {
          box-shadow: none;
          width: 100px;
        }
        .ant-select-selection-selected-value {
          margin-right: 5px;
          font-size: $fontSizeOriginal;
        }
        .ant-select-arrow-icon {
          color: $textColor;
        }
      }
    }
  }
  .logo {
    padding: $logoPadding;
    text-align: center;
    width: 250px;
    img {
      max-width: 100%;
    }
  }

  &.admin-header {
    .search-box {
      margin-left: unset;
    }

    .switch-page-mode-button {
      margin-left: auto;
      margin-right: 20px;
      border: none;
      box-shadow: none;
      color: $textColor;

      &:hover,
      &:focus {
        color: inherit;
      }

      span {
        padding-left: 10px;

        &.kdd-text {
          padding-top: 3px;
        }
      }
    }
  }

  .search-box {
    display: flex;
    align-items: center;
    transition: all 0.5s;
    overflow: auto;
    padding: 0 5px;
    margin-left: auto;
    margin-right: 20px;
    border-radius: 20px;

    &-enable {
      border: 1px solid $borderGrey;
    }

    .search-input {
      border: none;
      box-shadow: none;
      width: 0;
      padding: 0;
    }

    .search-input-enable {
      width: 200px;
      padding: 4px 11px;
    }

    .search-btn {
      border: none;
      &:hover, &:active, &:focus {
        color: $textBlack;
      }

      i {
        font-size: 18px;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: none;
      }
    }
  }
}

.universal-search-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
}

.universal-search {
  &__title {
    color: $textColor;
    font-family: $fontClanProBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
  &__actions {
    display: flex;
    align-items: center;
    padding: 45px 45px 20px;
    position: sticky;
    top: -1px;
    z-index: 5;
    background-color: $backgroundWhite;

    .search-action {
      margin-left: auto;
      display: flex;

      &-btn {
        text-transform: uppercase;
        height: 40px;
        padding: 0 30px;
        font-size: $fontSizeOriginal;
        font-family: $fontClanProBold;
        margin-left: 10px;
      }
    }
  }

  &__table {
    padding: 0 45px 20px;
    .ant-spin-nested-loading {
      border: 1px solid $borderGrey;
    }
    .search-highlight-text {
      font-family: $fontClanProBold;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    padding: 10px 45px;
    align-items: center;
    justify-content: flex-end;

    .back-to-top-btn {
      position: absolute;
      bottom: 104px;

      color: $mainTitle;
      font-family: $fontClanProBold;
      text-transform: lowercase;
      border: none;
      box-shadow: none;
      background-color: transparent;

      &:hover, &:focus, &:active {
        color: $mainTitle;
      }

    }

    .btn-cancel {
      height: 40px;
      padding: 0 18px;
      font-size: $fontSizeOriginal;
      font-family: $fontClanProBold;
      margin-left: 10px;
    }
  }

  &__view-details {
    text-align: center !important;
  }
}