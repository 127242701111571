.production-cycle-checkbox-icon {
  transform: translateY(-3px);
}

.production-cycle-checkbox-text {
  padding-left: 15px;
}

.production-cycle-comment {
  padding: 10px 20px;
}