.chemical-qc-form {
  width: 216px;
}

.chemical-qc-form-attr {
  width: 504px;
}

.chemical-qc-form-tr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
}

.chemical-qc-form-td {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 70px;
  input {
    margin-right: 5px;
  }
}

.chemical-qc-form-attr-label {
  width: 332px !important;
  height: 20px;
}

.chemical-qc-meta-data-container {
  display: flex;
}

.product-test-protocal-signature-test-information-container {
  padding: 0 40px;
}

.product-test-protocal-signature--label {
  display: flex !important;
  align-items: center;
  span {
    padding-top: 1px;
    padding-left: 5px;
  }
}

.protocal-test-result {
  display: flex;
  margin-top: 13px;
}
