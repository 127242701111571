.ant-modal-wrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  .ant-modal {
    padding-bottom: 0;
    top: auto;
    .ant-modal-content {
      border-radius: 0px;
      padding-bottom: 24px;
      .ant-modal-header {
        border-bottom: 0;
      }

      .ant-modal-body {
        // min-height: 47vh;
        max-height: 75vh;
        overflow: auto;
        padding: 24px 30px 0 24px;
        scrollbar-color: rgb(223, 221, 221) #f1f1f1;
        scrollbar-width: thin;
      }

      .ant-modal-footer {
        border-top: 0;
        font-family: $fontClanProBold;
        font-size: $fontSizeOriginal;
        font-weight: $fontWeightBold;

        .ant-btn {
          padding: $buttonPadding;
          align-items: center;
          height: auto;
          color: $textBlack;
          border-radius: 0px;

          &.btn-cancel {
            border: solid 1px $borderbtn;
            background-color: $backgroundWhiteBtn;
          }

          &.btn-ok {
            background-color: $backgroundYellow;
            border-color: $borderYellow;
            border: solid 1px $borderYellow;
          }
        }
      }
    }
  }
}

.raw-material-common-modal,.detergent-common-modal {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-size: $titleProducer;
        font-family: $fontClanProBold;
        color: $textColor;
        padding-left: 8px;
        padding-top: 8px;
      }
    }
  }
}

//Confirmation Modal
.base-confirm-modal {
  width: 600px !important;

  .content {
    display: flex;
    align-items: center;
    > span,
    div {
      font-size: 13px;
      margin-left: 10px;
      margin-top: 4px;
    }
    .content-deactive {
      margin-left: 35px;
    }
  }

  .warning-icon {
    color: #faad14;
    font-size: 25px;
    strong {
      color: #2b2b2b;
      font-size: 13px;
    }
  }

  .ant-modal-confirm-title {
    font-size: 18px;
    font-family: $fontClanProBold;
    color: $textColor;
  }

  .ant-modal-confirm-content {
    margin-top: 30px;
  }

  .ant-modal-confirm-btns {
    .ant-btn {
      padding: $buttonPadding;
      align-items: center;
      height: auto;
      color: $textBlack;
      border-radius: 0px;
      font-family: $fontClanProBold;
      &.ant-btn-loading {
        > i {
          vertical-align: middle;
        }
      }
    }

    button:nth-child(1) {
      border: solid 1px $borderbtn;
      background-color: $backgroundWhiteBtn;
    }

    button:nth-child(2) {
      background-color: $backgroundYellow;
      border-color: $borderYellow;
      border: solid 1px $borderYellow;
    }
  }
}
.base-error-modal {
  width: 600px !important;

  .content {
    display: flex;
    align-items: center;

    > span,
    div {
      font-size: 13px;
      margin-left: 10px;
      margin-top: 4px;
    }
  }

  .ant-modal-confirm-title {
    font-size: 18px;
    font-family: $fontClanProBold;
    color: $textColor;
  }

  .ant-modal-confirm-content {
    margin-top: 30px;
  }

  .ant-modal-confirm-btns {
    .ant-btn-danger {
      padding: $buttonPadding;
      align-items: center;
      height: auto;
      color: $textBlack;
      border-radius: 0px;
      font-family: $fontClanProBold;
      &.ant-btn-loading {
        > i {
          vertical-align: middle;
        }
      }
    }

    button:nth-child(1) {
      border: solid 1px $borderbtn;
      background-color: #ffed00;
    }
  }
}
