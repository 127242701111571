.container-type-filter-area {
  .filter-containerType {
    margin-right: 15px;
    .edit_filter {
      margin-top: -2px;
    }
  }
  .content-filter-form {
    min-width: 400px;
  }
  .export-button {
    height: 100%;
    padding: 9px 15px;
    background-color: $backgroundYellow;
    color: $textBlack;
    box-shadow: none;

    .anticon-loading {
      padding-right: 10px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $backgroundYellow;
      color: $textBlack;
      box-shadow: none;
      border-color: $borderGrey;
    }
  }
}