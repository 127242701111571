.table__item-count {
  font-size: 14px;
}

.table__pagination-wrapper {
  position: relative;
  background-color: transparent;
  padding: 20px 0;

  .table__visible-pages-wrapper {
    line-height: 2.8;

    .table__page-button:not(.table__page-button--active) {
      color: #afafaf;
    }

    > button {
      outline: none;
    }
  }

  .table__prev-page-wrapper,
  .table__next-page-wrapper {
    line-height: 2.3;
    padding: 0 6px;
    > button {
      outline: none;
    }
  }
}

.table__page-size-option {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: $fontSizeOriginal;
  color: $mainTitle;

  .ant-select {
    width: 5rem;
    margin: 0 1rem;

    .ant-select-arrow-icon {
      color: #000;
    }
  }
}


.table {
  &__back-to-top {
    // visibility: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    line-height: 2.4;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;

    >span {
      font-size: $headerFontSize;
      color: $sideBarBackground;
      text-transform: lowercase;
      font-weight: bold;
    }

    >i {
      vertical-align: 0em;
      margin-left: 0.5em;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  &__page-button {
    line-height: 1.6;
    font-size: 16px;
    color: #bdbdbd;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    >i {
      font-size: 20px;
    }

    &:disabled {
      cursor: not-allowed;
      color: #bdbdbd;
    }

    &--active {
      color: #000000;
      font-weight: bold;
    }
  }
}