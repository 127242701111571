.contact-list {
  .single-person {
    .show-more-less {
      color: $textGreen;
      text-decoration: underline;
      font-size: $fontSizeSmall;
      margin-top: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.raw-material-producer-tab {
  .filter-area {
    .content-filter-form {
      min-width: 500px;
      padding-left: 20px;
    }
    
    @media all and (max-width: 1168px) {
      .content-filter-form {
        top: 73px !important;
      }
    }

    @media screen and (max-width: $smallDesktop) {
      .content-filter-form {
        top: 61px !important;
      }
    }
  }
}
