.contact-list {
  .single-person {
    .show-more-less {
      color: $textGreen;
      text-decoration: underline;
      font-size: $fontSizeSmall;
      margin-top: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.detergent-producer-tab {
  .filter-area {
    .content-filter-form {
      min-width: 450px;
    }

    @media all and (max-width: 2749px) {
      .content-filter-form {
        top: 45px !important;
      }
    }
    
    @media all and (max-width: 1036px) {
      .content-filter-form {
        top: 54px !important;
      }
    }

    @media screen and (max-width: $smallDesktop) {
      .content-filter-form {
        top: 41px !important;
      }
    }
  }
}
