.raw-material {
  .check-differ {
    .ant-form-item-label {
      visibility: hidden;
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border: 1px solid $boderCheckbox;
    }
  }
  .ant-col-24 {
    width: 88%;
  }
  .price {
    .ant-input-group {
      display: flex;

      .item-input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      .item-selection {
        margin-left: 15px;

        .ant-select-selection {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;

          .ant-select-selection-selected-value {
            padding-right: 10px;
          }
        }
      }
    }
  }

  .upload {
    .button-upload {
      border-radius: 0px;

      span {
        font-size: $fontSizeOriginal;
        font-family: $fontClanProNews;
      }
    }
  }
}

.buttonCancelNo {
  display: none;
  // background: transparent !important;
  // border: transparent;
}

.raw-material-detail {
  .row-detail {
    display: flex;
    background: $rowBackgroundRawMaterialDetail;
    padding: 10px 20px;
    border-bottom: white solid 1px;

    .label {
      width: 35%;
      color: black;
      font-family: $fontClanProBold;
      font-weight: 400;
    }

    .value {
      width: 65%;
      color: #2b2b2b;

      &.status {
        span {
          padding: 8px 16px;
          border-radius: 15px;
          color: white;
        }

        &.Active {
          span {
            background: $textGreen;
          }
        }

        &.Inactive {
          span {
            background: $textGray;
          }
        }

        &.Blocked {
          span {
            background: $textRed;
          }
        }
      }

      .documents {
        display: flex;
        margin-bottom: 7px;

        i {
          font-size: 18px;
          margin-right: 2px;
        }

        a {
          color: rgba(0, 0, 0, 0.65);
          text-decoration: underline;
          margin-right: 15px;
        }

        &.last {
          margin-bottom: 0;
        }
      }
    }
  }

  .tabs-detergent-history {
    margin-top: 40px;

    .ant-tabs-bar {
      border-bottom: 5px solid #ffed00;

      .ant-tabs-tab {
        color: black;
        font-weight: bold;
        background: #c2c2c2;
        min-width: 110px;
        text-align: center;
        margin: 0;
        padding: 15px 20px;
        transition: 0.4s;

        font-family: $fontClanProBold;

        &.ant-tabs-tab-active {
          color: black;
          background: #ffed00;
          font-weight: bold;
        }

        &:hover {
          color: black;
          background: #ffed00;
        }
      }

      .ant-tabs-ink-bar {
        background: none;
      }
    }

    .ant-tabs-tabpane {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .raw-material-detail-list-table {
      margin-top: 15px;
      .ant-spin-nested-loading {
        background: #e8e8e8b3;
      }
      .ant-table-content {
        .ant-table-thead > tr:first-child > th:first-child {
          border-radius: 0;
          // border-top-left-radius: 0px !important;
        }

        .ant-table-thead > tr:last-child > th:last-child {
          border-right: 1px solid #e8e8e8;
          border-radius: 0;
          // border-top-right-radius: 0px !important;
        }

        .ant-table-tbody {
          tr td:last-child {
            border-right: 1px solid #e8e8e8;
            border-radius: 0;
          }

          .ant-table-row {
            .action-col {
              justify-content: start;
            }
          }
        }
      }

      .ant-spin-dot {
        font-size: 30px;
      }

      .ant-table-body {
        overflow: unset !important;
      }

      .ant-dropdown {
        top: 36px !important;

        &-menu-item {
          font-size: 11px;
        }

        .ant-dropdown-menu-item {
          padding: 3px 12px;
        }

        .ant-table-filter-dropdown-btns {
          font-size: 12px;
          padding: 5px 17px;

          .confirm, .clear {
            color: rgba(0, 0, 0, 0.65) !important;
          }
        }

        .ant-radio-inner {
          width: 14px;
          height: 14px;
          &::after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .detergent-list {
      min-height: 265px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .export-button {
          &:hover, &:active, &:focus, &:disabled {
            color: rgba(0,0,0,.65);
            border: 1px solid #d9d9d9;
            background-color: #f7f7f7;
          }

          .generate-file-icon {
            margin-bottom: 1px;
          }

          .anticon-loading {
            margin-right: 10px;
          }
        }
      }
    }

    .table__back-to-top {
      visibility: hidden;
    }
    .ant-table-row-cell-ellipsis {
      overflow-x: auto;

      .div-changes {
        overflow-x: auto;
        scrollbar-color: rgb(223, 221, 221) #f1f1f1;
        scrollbar-width: thin;
      }
    }
  }

  .form-btn-actions {
    padding-right: 0;
  }
}

.ant-dropdown {
  background-color: white;
  // padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dropdown-wrapper {
  display: inline-flex;
  float: right;

  .annual-tonnage-value {
    margin-right: 8px;
  }

  .drop-down-icon {
    float: right;
    position: relative;
    top: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $textGray;
  }

  &--float-left {
    float: left;
  }
}

.status-actions-container {
  display: inline-flex;
}

.annual-tonnage-container {
  min-width: 450px;
  padding: 30px;

  .container-title {
    color: $textBlack;
    font-family: $fontClanProBold;
    margin-bottom: 24px;
  }

  .drop-down-header-title {
    .annual-year {
      display: inline-block;
      min-width: 30px;
    }
  }

  .annual-year {
    margin-right: 24px;
    font-size: 12px;
  }

  .annual-tonnage,
  .total-annual-tonnage-value {
    font-family: $fontClanProBold;
  }

  .drop-down-header-title .total-annual-tonnage-value,
  .total-annual-tonnage-value {
    float: right;
  }

  .horizon-line {
    border-bottom: 1px solid $textPlayholder;
    margin: 8px 0;
  }
}
.disable-button {
  visibility: hidden;
}

.raw-material-list-table {
  th.ant-table-row-cell-break-word.PREVENT_ON_ROW_TRIGGER {
    text-align: right;
  }
  @media screen and (max-width: $smallDesktop) {
    .action-col {
      .BLOCK_RAW_MATERIAL,
      .UNLOCK_RAW_MATERIAL,
      .VIEW_RAW_MATERIAL_DETAIL,
      .EDIT_RAW_MATERIAL {
        margin-right: 2px !important;
      }
    }
  }
}
.rawMaterial-filter-area {
  .filter-partNumber {
    margin-right: 20px;
  }

  .sort-dropdown {
    margin-right: 20px;
  }

  .content-filter-form {
    min-width: 600px !important;
    .filter-content {
      min-width: 600px !important;
    }
    .filter-select {
      margin-top: 8px;
    }
  }

  @media screen and (max-width: $smallDesktop) {
    .filter-partNumber .sort {
      font-size: $fontSizeSmall;
    }

    .filter-partNumber .edit_filter {
      padding-top: 2px;
    }
      
    .filter-partNumber, .sort-dropdown {
      margin-right: 0;
    }

    .ant-dropdown-open {
      padding-bottom: 10px !important;
    }
  
    .search-place {
      margin-left: 4px;

      .ant-input {
        width: 170px;
      }
    }

    .add-button {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.value-commodity{
  color: #2b2b2b !important;
}
.style-autocomplete{
  width: 80% !important;
}
.style-supplier{
  padding-left: 20px;
  align-items: center;
  display: flex !important;
}
.supplier-name{
  display: flex;
  width: 100%;
  .row-detail-price{
    width: 60%;
    .label{
      width: 60% !important;
    }
    .value{
      width: 40% !important;
    }
  }
  .row-detail-supplier{
    width: 40%;
  }
}
