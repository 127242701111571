.form-btn-actions {
  text-align: right;
  margin-top: 15px;
  .ant-btn {
    padding: $buttonPadding;
    align-items: center;
    height: auto;
    color: $textBlack;
    border-radius: 0px;
    font-family: $fontClanProBold;
    &.btn-cancel {
      border: solid 1px $borderbtn;
      background-color: $backgroundWhiteBtn;
    }

    &.btn-save {
      background-color: $backgroundYellow;
      border-color: $borderYellow;
      border: solid 1px $borderYellow;
    }
  }

  button+button {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

//Form
.ant-input {
  border-radius: 0px;

  &:focus,
  &:hover {
    border-color: $colorInputFocus;
  }

  &:focus {
    box-shadow: 0 0 0 2px $colorInputFocus;
  }
}

.has-error {
  .ant-input {

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.ant-form-item {
  margin-bottom: 0px;
  .ant-form-item-label{
    text-align: right;
    padding-right: 20px;
    padding-top: 5px;
    & > label {
      font-size: $fontSizeOriginal;
      color: $textColor;
      &:after{
        content: none;
      }
    }
  }

  .ant-form-item-control-wrapper {
    .ant-select-selection__placeholder {
      font-family: $fontClanProNews;
      opacity: $opacityPlayholder;
      font-size: $fontSizeOriginal;
      color: $textPlayholder;
    }
    .ant-select-selection__choice {
      border-radius: 0px;
    }
  }

  label {
    font-size: $fontSizeOriginal;
  }
}