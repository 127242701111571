.procurement-email-attachment-file-modal {
  min-width: 700px;
  .ant-modal-content .ant-modal-body .procurement-email-attachments-container {
    display: flex;
  }
}

.procurement-email-attachment-uploaded,
.procurement-email-attachment-generated {
  width: 50%;
  word-break: break-all;
  margin: 0 5px;

  .document-files {
    margin-bottom: 15px;
    .document-file-category {
      display: inline-block;
      text-decoration: none !important;
      padding-left: 22px;
    }
  }

  .document-files:not(:nth-child(2)) {
    span:last-child {
      text-decoration: underline;
    }
  }
}

.procurement-email-attachment-header-title {
  font-size: small !important;
  color: #757575 !important;
}
