.db-sidebar {
  .ant-layout-sider-children {
    min-height: calc(100vh - 48px);
    .ant-menu-inline,
    .ant-menu-inline-collapsed {
      margin-top: $menuMarginTop;
      border-right: none;
      .ant-menu-item {
        font-size: $sideBarFontSize;
        font-family: $fontClanProNews;
        color: $sideBarTextcolor;
        a:hover {
          text-decoration: none;
        }
        .anticon {
          margin-right: $iconMarginRight;
          .icon-menu {
            width: 18px;
            &__hover {
              width: 18px;
              display: none;
            }
          }
          & + span {
            padding-top: 2px;
          }
        }
        &.ant-menu-item-selected,
        &.ant-menu-item-active,
        &.ant-menu-submenu-active,
        &.ant-menu-submenu-selected {
          .anticon {
            .icon-menu {
              display: none;
              &__hover {
                display: inline-block !important;
              }
            }
            & + span {
              color: $sideBarTextHover;
            }
          }
          .sidebar_group {
            color: $sideBarTextHover;
          }
        }
      }
    }
    // .ant-menu.ant-menu-inline-collapsed{
    //     .ant-menu-item{
    //         .icon-menu{
    //             margin-right: $iconMarginRight_Collapsed;
    //             &__hover{
    //                 margin-right: $iconMarginRight_Collapsed;
    //             }
    //         }
    //         &.ant-menu-item-selected,&.ant-menu-item-active{
    //             .icon-menu{
    //                 margin-right: $iconMarginRight_Collapsed;
    //                 &__hover{
    //                     margin-right: $iconMarginRight_Collapsed;
    //                     display: inline-block !important;
    //                 }
    //             }
    //         }
    //     }
    // }
  }
  .ant-layout-sider-trigger {
    text-align: left;
    padding-left: $itemPaddingLeft;
  }

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-trigger {
      padding-left: 0;
      text-align: center;
    }
  }
  .sidebar_name {
    padding-top: 4px;
    &:hover {
      color: $sideBarTextHover;
    }
  }

  .ant-menu-sub {
    background: #646464 !important;
    box-shadow: none !important;
  }
  .ant-menu-submenu-open,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    .ant-menu-inline {
      margin-top: 10px !important;
      margin-left: 12px !important;
    }
  }
}
.icon-edit {
  display: flex;
  // justify-content: center;
}
.ic-detergent {
  .icon-menu,
  .icon-menu__hover {
    height: 23px;
    margin-top: 8px;
  }
}
.ic-rawMaterialGroup {
  .icon-edit {
    margin-right: 18px !important;
    .icon-menu,
    .icon-menu__hover {
      width: 21px !important;
    }
  }
}
.ant-menu-submenu-title {
  &:hover {
    color: $sideBarTextHover !important;
  }
  .anticon {
    margin-right: $iconMarginRight;
    .icon-menu {
      width: 18px;
      &__hover {
        width: 18px;
        display: none;
      }
    }
    & + span {
      padding-top: 2px;
    }
  }
}
.ant-menu-submenu-open {
  color: $sideBarTextcolor;
}
.icon-others {
  width: 27px !important;
}
.submenu-others {
  .ant-menu-submenu-title {
    padding-left: 20px !important;
    .icon-edit {
      margin-right: 16px !important;
    }
  }
}

.ant-menu-item {
  a {
    &:hover {
      color: $sideBarTextHover;
      text-decoration: none !important;
    }
  }
}
.ant-menu-submenu-vertical {
  .ant-menu-submenu-title {
    left: -6% !important;
  }
}
// .ant-menu-item-selected {
//   a {
//     color: $sideBarTextHover;
//     background-color: white;
//   }
// }
.ant-menu-vertical {
  .ant-menu-item-selected {
    a {
      color: $sideBarTextHover;
    }
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
