.user-status-active {
  color: $textGreen;
}

.user-status-inactive {
  color: $textGray;
}

.search-user-box {
  width: 250px;
  height: 40px;
}

.user-list-container {
  background-color: white;
  padding: 10px;
  max-height: 400px;
  min-width: 260px;

  .user-name {
    text-transform: capitalize;
  }

  .button-wrapper {
    position: relative;

    .button-align-right {
      text-align: center;
    }
  }

  .kaercher-user-search-checkbox-group {
    max-height: 300px;
    min-height: 300px;
    overflow-y: scroll;
    width: 100%;    
  }

  .kaercher-user-search-button-wrapper {
    .ant-form-item-control-wrapper {
      width: 100%;
    }
  }

  .kaercher-user-search-submit-button {
    margin-bottom: 15px;
    text-align: center;
  }
}

.user-roles-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .user-fullname {
    text-align: center;
    font-family: $fontClanProBold;
    font-size: $headerFontSize;
    text-transform: capitalize;
  }

  .ant-radio-group {
    display: inline-grid;

    .ant-radio-wrapper {
      padding: 10px;
      position: relative;

      .ant-radio-inner {
        border-radius: 0 !important;
      }
      .ant-radio-inner:after {
        border-radius: 0;
        position: absolute;
        background-color: transparent;
        left: 6px;
        top: 0;
        width: 7px;
        border-bottom: 3px solid $textGray;
        height: 14px;
        border-right: 3px solid $textGray;
        transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}

.ant-checkbox-group {
  .ant-col-lg-15 {
    width: 100%;
  }
}

#user-management-dropdown-node {
  min-width: 270px;
}
