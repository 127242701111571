//Font Face
$fontClanProNews: "ClanPro-News";
$fontClanProMedium: "ClanPro-Medium";
$fontClanProBold: "ClanPro-Bold";

//Common
$fontSizeSmall: 11px;
$fontSizeOriginal: 13px;
$fontWeightBold: bold;
$titleFontSize: 22px;
$mainTitle: #646464;
$backgroundWhite: #ffffff;
$borderGrey: #d9d9d9;
$textColor: #2b2b2b;
$borderYellow: #d6ce61;
$textBlack: #000000;
$backgroundYellow: #ffed00;
$backgroundYellowOpacity: #ffed0080;
$yellowTextHover: #cec006;
$borderbtn: #e3e3e3;
$backgroundWhiteBtn: #f8f8f8;
$textWhite: white;
$marginFromSection: 20px;
$marginHorizontalElements: 20px;
$textPlayholder: #9b9b9b;
$backgroundUpload: #f2f2f2;
$titleProducer: 18px;
$colorDelete: #e2021a;
$fontSizeTitle: 20px;
$rowBackgroundRawMaterialDetail: #f5f5f5;
$colorInputFocus: #faad1433;
$boderCheckbox: #979797;
$hoverDropdown: #fdffeb;
$borderColorTable: #e8e8e8;
$textProgress: #0089bb;
//Modal
$buttonPadding: 8px 18px;

//Header
$headerHeight: 68px;
$headerFontSize: 16px;
//footer
$footerHeight: 50px;

//Sidebar
$sideBarFontSize: 14px;
$sideBarTextcolor: #ffffff;
$sideBarTextHover: #ffed00;
$sideBarBackground: #646464;
$itemPaddingLeft: 24px;
$itemMaginTopBot: 10px;
$logoPadding: 24px 0;
$iconMarginRight: 20px;
$menuMarginTop: 40px;

//Table
$fontColorTable: #2b2b2b;
$hoverInput: #fdffeb;

//Raw Material
$textGreen: #4aad40;
$textOrange: #f3a937;
$textGray: #757575;
$textGrayOpacity: #757575;
$textRed: #b61a2d;
$lightBlueText: #238c96;
$textErrorMessage: #f5222d;

//Detergent
$backgroundGreen: #4bad41;

//Opacity
$opacityPlayholder: 0.99;

// $yellow: yellow;
// $background-white: #ffffff;
// $background-gray: #ededed;
// $background: #f3f3f3;
// $header-background: white;
// $background-yellow: #ffed00;
// $primary-color: yellow;
// $dark-text-color: #646464;
// $light-text-color: #ffffff;
// $title-text-color: gray;
// $color-text-subcontent: #999999;
// $btn-primary-bg: linear-gradient(to bottom, #fff465, #ffed00);
// $btn-default-bg: linear-gradient(to bottom, #f8f8f8, #ededed);
// $view-list-border: #f3f3f3;
// $border-default: #e3e3e3;
// $border-box-color: #d9d9d9;
// $white: #ffffff;
// $multi-table-header-bg: #f9f9f9;
// $grey-text-color: #757575;
// $border-error-red: red;
// $color-red: #e2021a;
// $black-color: #000000;

// break points
$smallDesktop: 1024px;
$mediumDesktop: 1440px;
