.formular-selection {
  .single-field {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    padding-right: 0;
    background: $rowBackgroundRawMaterialDetail;
    .label {
      width: 60%;
      color: black;
      font-weight: bold;
    }
    .ant-input-number {
      width: 40%;
      .ant-input-number-input {
        text-align: end;
      }
    }
    .ant-input-group-wrapper {
      width: 50%;
      .ant-input {
        color: $textColor;
        text-align: right;
      }
      .ant-input-group-addon {
        border: none;
        background: $rowBackgroundRawMaterialDetail;
        width: 60px;
        padding-top: 3px;
        color: black;
      }
    }
    .total-label {
      color: $textPlayholder;
      font-weight: bold;
      width: 50%;
    }
    .value-total {
      color: $textGreen;
      font-size: 18px;
      font-weight: bold;
      width: calc(50% - 60px);
      text-align: right;
    }
    .percent-sign {
      width: 60px;
      text-align: center;
      color: $textGreen;
      font-weight: bold;
      font-size: 18px;
    }
    .percent {
      width: 60px;
      text-align: center;
      color: #000000;
      font-weight: bold;
      font-size: 14px;
    }
    .order-column{
      width: 10%;
      text-align: center;
      margin-right: 10px;
    }
  }
  .error-message-overpercent {
    color: $textErrorMessage;
    font-size: $sideBarFontSize;
  }
}
.virtualizedSelect-style{
    .Select-value{
      margin-top: 11px !important;
      color: $textColor !important;
      background-color: #ffffff !important;
      border: 1px solid $borderGrey!important;
      &:hover{
        border: 1px solid $colorInputFocus !important;
      }
    }
    .Select-value-icon{
      color: $textColor !important;
      border: 0px !important;

      &:hover{
        border: 0px !important;
        background-color: #ffffff !important;
      }
    }
    .Select-placeholder{
      padding-top: 4px !important;
    }
}
