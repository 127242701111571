//Text Style
.text-black {
  color: black;
}

.text-bold {
  font-weight: bold;
}

//Ant Menu Overide
.ant-menu-dark,
.ant-layout-sider-dark,
.ant-layout-sider-trigger {
  background: $sideBarBackground;
}

.ant-menu-dark {
  .ant-menu-item {
    color: $sideBarTextcolor;
  }
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: $sideBarBackground;
}

.right-side {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 68px);
  scrollbar-color: rgb(223, 221, 221) #f1f1f1;
  scrollbar-width: thin;
  margin-top: 20px;

  .content {
    padding: 0 20px 20px;
  }
}

.ant-input-number {
  width: 100%;
  border-radius: 0;

  &:hover {
    border-color: $colorInputFocus;
  }

  &:active {
    box-shadow: 0 0 0 2px $colorInputFocus !important;
  }

  &:focus {
    border-color: $colorInputFocus !important;
    box-shadow: 0 0 0 2px $colorInputFocus;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-focused {
  border-color: $colorInputFocus;
  box-shadow: 0 0 0 2px $colorInputFocus;
}

//Ant Table Overide
.ant-table-wrapper {
  background: $backgroundWhite;
  font-size: $fontSizeOriginal;

  .ant-table-thead {
    tr th {
      background: $backgroundWhite;
      color: $textGrayOpacity;
      font-size: $fontSizeSmall;
      font-family: $fontClanProNews;
      border-left: none;
      border-right: none;
    }
  }

  .ant-table-tbody {
    color: $fontColorTable;

    .ant-table-row {
      cursor: pointer;
      transition: all 0s, height 0s;

      .green-text {
        color: $textGreen;
      }

      .grey-text {
        color: $textGray;
      }

      .orange-text {
        color: $textOrange;
      }

      .red-text {
        color: $textRed;
      }

      .light-blue-text {
        color: $lightBlueText;
      }

      &:hover {
        .action-col {
          .action-item-visible-bold {
            display: block;
          }

          .action-item-visible {
            display: none;
          }
        }

        .annual-tonnage-item,
        .annual-demand-item,
        .raw-material-status-cells {
          color: $borderGrey;
        }
      }

      .action-col {
        display: flex;
        justify-content: flex-end;
        visibility: hidden;

        .ant-btn {
          padding-left: 5px;
          padding-right: 5px;
        }

        .action-item {
          text-decoration: underline;
          cursor: pointer;
          font-size: 24px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .duplicate-icon {
          width: 19px;
        }

        .action-item-visible,
        .action-item-visible-bold {
          &:last-child {
            margin-right: 20px;
          }
        }

        .action-item-visible-bold {
          display: none;
        }

        .action-item-visible,
        .action-item-visible-no-effect {
          visibility: visible;
        }

        .action-item-visible-no-effect {
          margin-right: 1rem;
        }
      }

      td {
        border-left: none;
        border-right: none;
        background: $textWhite;
        font-size: $fontSizeOriginal;
        vertical-align: center;
      }

      &:hover {
        font-weight: bold;
        color: black;

        .action-col {
          visibility: visible;
        }
      }
    }
  }

  @media screen and (max-width: $mediumDesktop) {
    .ant-table-thead>tr>th {
      padding: 12px;
    }
    .ant-table-tbody>tr>td {
      font-size: 12px !important;
      padding: 12px;
    }
  }

  @media screen and (max-width: 1080px) {
    .ant-table-thead>tr>th {
      padding: 10px;
    }
    .ant-table-tbody>tr>td {
      font-size: 11px !important;
      padding: 10px;
    }
  }

  @media screen and (max-width: $smallDesktop) {
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      padding: 4px;
    }
  }
}

.ant-table-tbody,
.ant-table-thead {
  > tr {
    &.ant-table-row-hover,
    &:hover {
      &:not(.ant-table-expanded-row) {
        &:not(.ant-table-row-selected) {
          > td {
            background: #fdffeb;

            .ant-input,
            .ant-select-selection {
              background: #fdffeb;
            }
          }
        }
      }
    }
  }
}

.ant-select-focused,
.ant-select-open {
  .ant-select-selection {
    box-shadow: 0 0 0 2px $colorInputFocus;
    border-color: $colorInputFocus;
  }
}

.ant-select-selection {
  border-radius: 0;

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px $colorInputFocus;
    border-color: $colorInputFocus;
  }

  &:hover {
    border-color: $colorInputFocus;
  }
}

//Content Style
.detergent-database-content {
  display: flex;
  align-items: center;
  margin-bottom: $marginFromSection;

  .main-title {
    font-size: $fontSizeTitle;
    color: $mainTitle;
  }

  .filter-area {
    display: flex;
    margin-left: auto;
    align-items: center;

    .filter-selection {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      background: white;
      border: solid 1px $borderGrey;
      cursor: pointer;
      font-size: $fontSizeOriginal;
      margin-left: $marginHorizontalElements;

      &:hover {
        border: solid 1px $borderYellow;
        font-weight: bold;
      }

      .dropdown-text {
        margin-right: 5px;
      }
    }

    @media screen and (max-width: $smallDesktop) {
      .sort-dropdown .sort,
      .sort-dropdown .edit_filter,
      .search-place .ant-input,
      .add-button {
        font-size: $fontSizeSmall;
      }
      .sort-dropdown .edit_filter {
        padding-top: 5px;
      }
      .add-button {
        padding: 10px;
      }
    }
  }

  .search-place {
    width: auto;
    margin-left: $marginHorizontalElements;

    .ant-input {
      border-radius: 0;
      font-size: $fontSizeOriginal;
      padding: 10px 15px;
      width: 220px;
      height: auto;

      &:hover {
        border-color: $borderYellow;
      }

      &:focus {
        border-color: $borderYellow;
        box-shadow: 0 0 0 2px rgba(214, 206, 97, 0.2);
      }
    }
  }

  .add-button {
    display: flex;
    align-items: center;
    height: auto;
    padding: 10px 20px;
    border-radius: 0;
    margin-left: 20px;
    font-size: $fontSizeOriginal;
    font-family: $fontClanProBold;
    border: solid 1px $borderGrey;
    background: $backgroundYellow;
    color: $textBlack;
    transition-duration: 0.5s;

    .add-icon {
      font-size: 14px;
      margin-right: 6px;
      width: 15px;
      height: 15px;
    }

    .text {
      padding-top: 2px;
    }

    &:hover {
      color: $textBlack;
      background: $backgroundYellowOpacity;
    }
  }

  //SORT BUTTON
  .sort-dropdown {
    display: flex;
    cursor: pointer;
    padding: 11px 21px;
    align-items: center;

    &.ant-dropdown-open {
      border: 1px solid #d9d9d9;
      padding: 11px 20px;
      background-color: #ffffff;
      border-bottom: none;
      // box-shadow: 0 2px 8px rgba(0,0,0,.15);
      position: relative;
      z-index: 1060;
    }

    .edit_filter {
      margin-right: 5px;
      margin-top: -6px;

      .anticon-filter {
        color: $mainTitle;
      }

      svg {
        width: 13px;
        height: 13px;
      }
    }

    .edit_caret {
      margin-right: 5px;
      display: grid;
      margin-top: -2px;

      .anticon {
        color: $sideBarBackground;

        &.anticon-caret-up {
          margin-bottom: -2.5px;
        }

        &.anticon-caret-down {
          margin-top: -2.5px;
        }
      }

      svg {
        width: 13px;
        height: 13px;
      }
    }

    .sort {
      font-size: $fontSizeOriginal;
      color: $mainTitle;
    }
  }
}

.menu-sort {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;

  .sort-item {
    display: flex;
    align-items: center;
    padding: 10px 40px 5px 20px;
    min-width: 200px;

    &:hover {
      color: $yellowTextHover;
    }

    i {
      margin-left: 10px;
      margin-top: -4px;
      font-weight: bold;
    }
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(223, 221, 221);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 166, 166);
}

//Filter & SORT
.content-filter-form {
  min-width: 600px;
  padding: 20px 20px;
  padding-left: 0px;
  background: white;
  // box-shadow: 0 2px 8px rgba(0,0,0,.15);
  margin-top: -4px;
  box-shadow: rgb(204, 204, 204) 0px 3px 6px 2px;
  border-top: 1px solid #ccc;
}

.content-sort-form {
  box-shadow: rgb(204, 204, 204) 0px 3px 6px 2px;
  border-top: 1px solid #ccc;

  .ant-menu {
    margin-left: 1px;
    margin-top: -5px;
  }
}

.ant-dropdown-placement-bottomLeft {
  margin-left: 41px;
}

//Modal Style

.ant-spin-nested-loading {
  // background: rgba(0, 0, 0, 0.7);
  height: inherit;
  .ant-spin-container {
    background: $borderColorTable;
  }

  .ant-spin {
    color: $backgroundYellow;
    max-height: unset !important;

    .ant-spin-dot {
      margin: -10px 0 !important;
      transform: translateX(-50%);
    }
  }
}

.ant-spin-dot {
  font-size: 35px;
}

._loading_overlay_content {
  color: $backgroundYellow;
  font-size: 35px;
}

.ant-radio-wrapper {
  &:hover {
    .ant-radio {
      border-color: $sideBarBackground;
    }
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: $sideBarBackground;
      }
    }

    .ant-radio-input {
      &:focus {
        & + .ant-radio-inner {
          border-color: $sideBarBackground;
        }
      }
    }

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: $sideBarBackground;
    }

    &.ant-radio-checked {
      &:after {
        border: 1px solid $sideBarBackground;
      }

      .ant-radio-inner {
        &:after {
          background-color: $sideBarBackground;
          top: 6px;
          left: 6px;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .ant-form-item {
    .ant-form-item-label {
      padding: 0;
      line-height: normal;
    }

    margin-top: 20px;
  }
}

.ant-modal-confirm {
  .ant-modal-close {
    display: block;
  }
}

// Style checkbox
.ant-checkbox-wrapper {
  .ant-checkbox {
    &:after {
      border: 1px solid $boderCheckbox;
    }

    .ant-checkbox-inner {
      background-color: $backgroundWhite;

      &:after {
        border-color: $sideBarBackground;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner::after {
        width: 6px;
        height: 14px;
        top: 42%;
        left: 26%;
      }
    }
  }
}

//Dropdown options hover
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $hoverDropdown;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: white;
}

.ant-select-dropdown {
  border-radius: 0;
}

//Ant Button
.ant-btn {
  border-radius: 0 !important;
}

.upload-file-button {
  color: $textColor;

  &:hover,
  &:active,
  &:focus {
    border-color: $colorInputFocus;
    color: inherit;
  }

  .anticon-upload {
    color: black;
    vertical-align: 1px;
    font-size: 20px;
  }
}

.ant-upload {
  .ant-btn.item-button {
    border-radius: 0px;
  }
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px !important;
}

.ant-table-thead > tr:last-child > th:last-child {
  border-top-right-radius: 0px !important;
}

.margin-left {
  margin-left: 38px;
}

.action-margin-left {
  margin-left: 35px;
}

.action-btn-margin-left {
  margin-left: 36px;
}

.ant-table-tbody {
  .expanded-rows {
    > td {
      border-bottom: 1px dashed $borderColorTable !important;
      border-top: 1px dashed $borderColorTable !important;
    }
  }

  .expanded-rows:first-child {
    > td {
      border-top: 1px dashed $borderColorTable !important;
    }
  }

  .expanded-rows:last-child {
    > td {
      border-bottom: 1px solid $borderColorTable !important;
    }
  }
}

.fixed-table-layout {
  table {
    table-layout: fixed;
  }
}

#parent-node,
#parent-node-1 {
  position: relative;

  .ant-select-dropdown {
    position: relative;
  }
}

// #parent-node-1 {
// }

.view-detail-modal {
  .row-detail {
    display: flex;
    align-items: center;
    background: $rowBackgroundRawMaterialDetail;
    padding: 10px 20px;
    border-bottom: white solid 1px;
    font-size: 13px;

    .label {
      width: 30%;
      color: black;
      font-family: $fontClanProBold;
    }

    .value {
      width: 70%;
      display: flex;
      justify-content: space-between;

      &.status {
        span {
          padding: 8px 16px;
          border-radius: 15px;
          color: white;
          background-color: $backgroundGreen;
        }

        &.Active {
          span {
            background: $textGreen;
          }
        }

        &.Inactive {
          span {
            background: $textGray;
          }
        }

        &.Inprogress {
          span {
            background: $lightBlueText;
          }
        }

        &.Blocked {
          span {
            background: $textRed;
          }
        }
      }
    }
  }

  .tabs-detergent-history {
    margin-top: 40px;

    .ant-tabs-bar {
      border-bottom: 5px solid #ffed00;

      .ant-tabs-tab {
        color: black;
        font-weight: bold;
        background: #c2c2c2;
        min-width: 110px;
        text-align: center;
        margin: 0;
        padding: 15px 20px;
        transition: 0.4s;
        margin-left: 1px;
        font-family: $fontClanProBold;
        font-size: 13px;

        &.ant-tabs-tab-active {
          color: black;
          background: #ffed00;
          font-weight: bold;
        }

        &:hover {
          color: black;
          background: #ffed00;
        }
      }

      .ant-tabs-ink-bar {
        background: none;
      }
    }

    .ant-tabs-tabpane {
      // padding-top: 10px;
      // padding-bottom: 20px;
    }

    .raw-material-detail-list-table,
    .precalculation-list-table,
    .measurement-data-table {
      margin-top: 15px;

      .ant-spin-nested-loading {
        background: #e8e8e8b3;
      }

      .ant-table-content {
        .ant-table-thead > tr:first-child > th:first-child {
          border-radius: 0;
          // border-top-left-radius: 0px !important;
        }

        .ant-table-thead > tr:last-child > th:last-child {
          border-radius: 0;
          // border-top-right-radius: 0px !important;
        }

        .ant-table-tbody {
          tr td:last-child {
            border-radius: 0;
          }

          .ant-table-row {
            .action-col {
              justify-content: start;
              visibility: visible;
            }
          }
        }
      }

      .ant-spin-dot {
        font-size: 30px;
      }

      .ant-table-row-cell-break-word {
        border-bottom: 2px solid $backgroundWhite;
      }

      .ant-table-bordered .ant-table-body > table {
        border: transparent;
      }

      .ant-select-dropdown-placement-bottomLeft,
      .ant-select-dropdown--single {
        width: 7%;
      }
    }

    .precalculation-list-table {
      .ant-table-content {
        .ant-table-thead {
          tr > th:not(:first-child) {
            .ant-table-header-column {
              float: right;
            }
          }

          tr > td:first-child {
            padding: 10px 16px;
          }
        }

        .ant-table-footer {
          padding: 0;
          font-family: $fontClanProBold;

          td {
            padding: 4px 16px;
          }

          .sum-prices-title {
            color: $textPlayholder;
          }

          .sum-prices {
            float: right;
            text-align: right;
            color: $textGreen;
          }
        }
      }
    }

    .table__back-to-top {
      visibility: hidden;
    }

    .ant-table-row-cell-ellipsis {
      overflow-x: auto;

      .div-changes {
        overflow-x: auto;
        scrollbar-color: rgb(223, 221, 221) #f1f1f1;
        scrollbar-width: thin;
      }
    }

    .history-details {
      .title {
        font-size: 13px;
      }

      .history-list-detail {
        border: 1px solid $borderGrey;
        margin-top: 10px;

        .table__pagination-wrapper {
          .table__page-size-option {
            padding-left: 16px;
          }
        }
      }
    }

    .rawMaterial-detail {
      border: 1px solid $borderGrey;
    }
  }

  .form-btn-actions {
    padding-right: 0;
  }

  .precalculation-details {
    .precalculation-container-size {
      height: 35px;
      margin-bottom: 10px;

      .container-size-wrapper {
        float: right;
        margin-bottom: 20px;
        display: inline-flex;

        .container-size-title {
          font-family: $fontClanProBold;
          color: $textBlack;
        }

        .container-size-input {
          width: 50%;
        }

        span:nth-child(2n + 1) {
          padding: 5px;
        }
      }
    }

    .precalculation-list-table {
      input {
        text-align: right;
      }

      .raw-material-column-title {
        text-transform: uppercase;
      }

      .ant-table-row {
        .ant-table-row-cell-break-word {
          padding: 4px;
          padding-left: 16px;

          &:first-child {
            padding-top: 12px;
          }
        }

        .precalculation-ratio {
          padding: 4px;
        }
      }
    }

    .ant-spin-nested-loading .ant-spin-container {
      background-color: $backgroundWhite;
    }
  }

  // .ant-select-dropdown--single,
  // .ant-select-dropdown-placement-bottomLeft,
  // .ant-select-dropdown-hidden {
  //   width: 7% !important;
  // }
  .ant-table-row-cell-break-word {
    font-family: $fontClanProBold;
  }
}

.align-right {
  text-align: right;
}

.margin-right {
  margin-right: 16px;
}

.margin-right-5 {
  margin-right: 5px;
}

.attach-icon-align {
  position: relative;
  top: -2px;
}

.line-margin-top {
  margin-top: 16px;
}

.edit-form-fields {
  .ant-form-item {
    display: flex;
    background: #f5f5f5;
    border-bottom: white solid 1px;
    padding: 0 20px;

    .ant-form-item-label {
      width: 25%;
      text-align: left;
      line-height: inherit;
      padding: 0;
      align-self: center;

      > label {
        margin: 0;
        color: black;
        font-family: "ClanPro-Bold";
      }
    }

    .ant-form-item-control-wrapper {
      width: 75%;
    }
  }

  &.no-background-fields {
    .ant-form-item {
      background: transparent;
      border-bottom-color: transparent;
    }

    .ant-form-item-label {
      text-align: right;
      padding-right: 20px;
    }
  }
}

.padding-zero {
  padding: 0;
}

.icon-button {
  i {
    transform: translate(-7px, -2px);
  }
}

.ant-btn.yellow-button {
  background-color: $backgroundYellow;
  color: $textColor;
  font-weight: bold;
  border-color: $borderbtn;
  box-shadow: none;
  line-height: 32px;

  > i {
    vertical-align: inherit;
    margin-right: 5px;
  }

  :hover {
    background-color: $backgroundYellowOpacity;
    box-shadow: none;
  }

  &:after {
    display: none;
  }
}

.table-background-gray-color {
  .ant-table-thead > tr > th {
    border: none;
  }

  .ant-table-tbody {
    .ant-table-row {
      > td {
        background-color: $rowBackgroundRawMaterialDetail;
        border-color: white;
      }
    }
  }
}

.filter-form-style {
  top: 45px !important;
}

.generate-file-icon {
  margin-right: 10px;
}

.ant-table-row-cell-ellipsis {
  overflow-x: auto;

  .div-changes {
    overflow-x: auto;
    scrollbar-color: rgb(223, 221, 221) #f1f1f1;
    scrollbar-width: thin;
  }
}

.ant-table-column-title,
.ant-table-row-cell-break-word {
  word-break: keep-all;
  overflow-wrap: anywhere;
}

.hide-form-item-error-message {
  .ant-form-explain {
    display: none;
  }
}

.float-right {
  float: right;
}

.text-align-right {
  text-align: right;
}

.text-align-right-important {
  text-align: right !important;
}

.hidden {
  visibility: hidden !important;
}
.record-belong{
  .ant-form-item{
    .ant-form-item-label{
      > label{
        white-space: normal;
      }
    }
  }
}

.base-table--sticky-header {
  table > thead {
    position: sticky;
    top: -1px;
    z-index: 2;
  }
  .ant-spin-container {
    overflow: unset;
  }
}
